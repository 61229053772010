import React, {useState} from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/products.css"


import img1 from "../images/balia-swierkowa-165x125-45mm-grzalka/0001.webp"
import img2 from "../images/balia-swierkowa-165x125-45mm-grzalka/0002.webp"
import img3 from "../images/balia-swierkowa-165x125-45mm-grzalka/0003.webp"
import img4 from "../images/balia-swierkowa-165x125-45mm-grzalka/0004.webp"
import img5 from "../images/balia-swierkowa-165x125-45mm-grzalka/0005.webp"
import img6 from "../images/balia-swierkowa-165x125-45mm-grzalka/0006.webp"
import img7 from "../images/balia-swierkowa-165x125-45mm-grzalka/0007.webp"
import img8 from "../images/balia-swierkowa-165x125-45mm-grzalka/0008.webp"

import raty from '../images/raty-banner.jpg'

import ImageGallery from 'react-image-gallery';
 
const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
  {
    original: img6,
    thumbnail: img6,
  },
  {
    original: img7,
    thumbnail: img7,
  },
  {
    original: img8,
    thumbnail: img8,
  },
];

const ProductView8 = () => {

  const [price, setPrice] = useState(7999);


  return(
  <Layout>
    <SEO 
      title="Balia ogrodowa" 
      description = { SEO.description }
      urlName="balia-swierkowa-165cm-x-125cm"
    />
    <div className="banner">
        <div className="bannerPlate">
          <p>Balia świerkowa</p>
        </div>
    </div>
    <div className="productViewPlate">

      <div className="gallery">
        <ImageGallery slideInterval="2000"  items={images} />
      </div>
      <div className="productDescription">
          <div id="prodTop"></div>
          <div id="prodLeft"></div>
          <div id="prodBottom"></div>
          <div id="prodRight"></div>
          <div id="productTitle">Balia świerkowa z piecem - balik 45mm</div>
          <div id="productPrice">{price}zł
          </div>
          <div className="productOption">
              <div className="productDescriptionText">
                  <h3>Opis</h3>
                  <p>
                    Balia ogrodowa wraz z piecem wykonana z drewna świerkowego o wymiarach 165cm na 125cm. Nasz produkt to idealny przedmiot do relaksu na świeżym powietrzu w aurze po południowego słońca ! Balia ogrodowa jest w pełni skompletowana. Istnieje możliwość zakupu produktu na raty.<br/>
                  </p>
                  <h3>Dane techniczne:</h3>
                  <ul>
                    <li>Pokrywa</li>
                    <li>Spiralna grzałka na drewno</li>
                    <li>Schody</li>
                    <li>Ławki</li>
                    <li>ścianka wewnętrzna oddzielająca miejsca siedzące od pieca</li>
                    <li>średnica zewnętrzna: 165cm x 125cm - wysokość 96cm</li>
                    <li>średnica zewnętrzna: 155cm x 115cm - wysokość 80cm</li>
                    <li>Grubość ścian: 45 mm</li>
                  </ul>
                  <a href="https://epos.inbank.pl/pl/drewhaus">
                  <img src={raty} className="creditBanner" alt="domek-ogrodowy-raty"/>
                  </a>
                  <p><b>Domki ogrodowe możesz obejrzeć w Gnieźnie! Zajrzyj do zakładki&nbsp; 
                    <Link to="/contact" style={{ color: '#765F52' }} >
                      kontakt
                    </Link> i sprawdź gdzie nas znajdziesz!</b></p>

              </div>
          </div>
      </div>
    </div>
  </Layout>
  )}

export default ProductView8


